import { Link } from 'react-router-dom';

function Logo () {
    return (
        <Link to='/'>
            <svg xmlns="http://www.w3.org/2000/svg" width="73" height="23" viewBox="0 0 73 23" fill="none">
                <path d="M14.2184 11.4837C14.2184 5.17617 19.4914 0 25.9065 0C32.5039 0 37.661 5.24946 37.661 11.4837C37.661 17.8237 32.4294 23 25.981 23C19.3754 23 14.2184 17.7505 14.2184 11.4837ZM36.9904 12.4685C36.9904 6.12028 31.3947 0.651053 24.9381 0.651053C19.2265 0.651053 14.8144 4.95641 14.8888 10.6047C14.9634 16.9122 20.4929 22.3489 26.9413 22.3489C32.6198 22.3489 36.9904 18.0435 36.9904 12.4685Z" fill="#303030"/>
                <path d="M40.2478 22.8939V22.7475C40.9183 22.7475 41.2991 22.5765 41.2991 21.8115V1.21236C41.2991 0.447348 40.9679 0.260193 40.2644 0.25204V0.105469H43.3768C46.7541 0.105469 49.345 2.73432 49.345 5.97355C49.345 9.22093 46.7541 11.8822 43.3768 11.8822H42.4166V21.8034C42.4166 22.5684 42.7808 22.7475 43.4513 22.7475V22.8939L40.2478 22.8939ZM42.4166 11.7357H43.3437C46.0836 11.7357 48.1944 9.11513 48.1944 5.97355C48.1944 2.84013 46.0836 0.25204 43.3437 0.25204H42.8553C42.5987 0.25204 42.4166 0.431041 42.4166 0.691481V11.7357Z" fill="#303030"/>
                <path d="M51.5701 22.8939V22.7475C52.2323 22.7475 52.6047 22.5684 52.6047 21.8034V1.19614C52.6047 0.431041 52.2323 0.25204 51.5701 0.25204V0.105469H54.757V0.25204C54.0947 0.25204 53.7223 0.431041 53.7223 1.19614V21.8034C53.7223 22.5684 54.0947 22.7475 54.757 22.7475V22.8939H51.5701Z" fill="#303030"/>
                <path d="M59.5405 20.9978C59.2176 20.8025 59.0685 20.7782 58.8948 20.7782C58.2657 20.7782 58.1746 21.4862 58.0587 22.1292H57.9097V18.5562H58.0587C59.8384 21.0304 62.6528 22.8209 65.7653 22.8209C69.3992 22.8209 71.9984 20.6642 71.9984 17.6041C71.9984 14.4706 68.7619 12.0616 65.3265 10.2792C61.7257 8.40725 58.9858 6.85277 58.9858 3.97163C58.9858 1.64398 60.7986 0 63.6213 0C64.3995 0 65.2272 0.0488266 66.8414 0.577767C67.1062 0.667358 69.7965 1.53002 70.2931 1.63583V3.78448H70.1442C70.1855 2.80785 69.7221 2.1404 68.8861 1.70911C67.2553 0.870817 65.6162 0.284805 63.878 0.284805C61.4692 0.284805 60.0536 1.57069 60.0536 3.61354C60.0536 6.38071 63.2572 7.68291 66.2867 9.40018C69.8876 11.443 73.0001 13.4451 73.0001 17.132C73.0001 20.5177 69.7387 23 65.9143 23C63.4724 23 61.8665 22.4058 59.5405 20.9978Z" fill="#303030"/>
                <path d="M12.1137 21.93C12.213 22.7106 13.1145 22.7431 13.2634 22.7431V22.8895H10.1122V22.7431C10.228 22.7431 11.1047 22.7106 11.0055 21.9137L10.9697 1.02256L6.63015 20.0108L7.0023 22.8895H5.87746L1.31506 2.03907L1.41123 22.1007C1.34506 22.7919 2.1225 22.7431 2.2714 22.7431V22.8895H0.377405V22.7431C0.972909 22.7431 1.21273 22.5642 1.27061 21.9381L1.22404 1.4698L1.18273 1.13643C1.09171 0.339468 0.107484 0.266317 0 0.266317V0.119916H3.18421V0.266317C2.77902 0.266317 2.22483 0.298796 2.31585 1.13643L6.53913 19.2382L10.8456 0.737948C10.5561 0.298795 9.91922 0.266317 9.81173 0.266317V0.119916H12.996V0.266317C12.8471 0.266317 12.0118 0.266317 12.111 1.06323L12.1137 21.93Z" fill="#303030"/>
            </svg>
        </Link>
    )
};

export default Logo;