import './Header.scss';
import Logo from './Logo';
import useScrollDirection from '../../hooks/useScrollDirection';
import classNames from 'classnames';


function Header () {

    const scrollDirection = useScrollDirection();

    const headerStyles = classNames('header', {
        hide: scrollDirection === 'down',
    });

    return (
        <header className={headerStyles}>
            <div className='header-container'>
                <Logo/>

                <a href='https://www.instagram.com/alisa_vasulunka/?hl=uk' target='_blank' rel='noreferrer'>
                    <span>@alisa_vasulunka</span>
                </a>
            </div>
        </header>
    )
};

export default Header;