
import './App.scss';
import Header from './components/Header/Header'
import MainBlock from './components/MainBlock/MainBlock';
import Footer from './components/Footer/Footer';
import PublicOffer from './components/PublicOffer/PublicOffer';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import { Route, Routes, useLocation } from 'react-router-dom';
import useScrollToTop from './hooks/useScrollToTop';
import {useEffect} from 'react';

function App() {
  const location = useLocation();
  const scrollToTop = useScrollToTop();

  useEffect(() => {
    scrollToTop({ behavior: 'smooth' });
  }, [location.pathname, scrollToTop]);

  return (
    <div className="App">
      <Header/>
      
        <Routes>
          <Route path='/' element={<MainBlock/>}/>
          <Route path='/public-offer' element={<PublicOffer/>}/>
          <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
        </Routes>
      
      <Footer/>
    </div>
  );
}

export default App;
