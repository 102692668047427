import './PublicOffer.scss';

function PublicOffer () {
    return (
        <section className='public-offer'>
            <div className='public-offer-container'>
                <iframe 
                    src='https://docs.google.com/document/d/e/2PACX-1vS17LD-41xiux4VVqZDYykKfo-auVcAIzJv_2bFVb0Aflr_UIRWPTpg-oFRVAdsRV4x_lsfcebBG14F/pub?embedded=true'
                    title='Публічна оферта'
                />
            </div>
        </section>
    )
};

export default PublicOffer;