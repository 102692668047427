import {useRef, useEffect} from 'react';
import gsap, { Expo, Power3 } from 'gsap';

function DonateButton (props : any) {
    const buttonRef = useRef<HTMLDivElement>(null);
    const buttonBgRef = useRef<HTMLDivElement>(null);

    const mouseOverButton = () => {
        gsap.killTweensOf(buttonBgRef.current);

        gsap.fromTo(
            buttonBgRef.current, 
            {
                y: '0%',
            }, 
            {
                ease: Power3.easeOut,
                duration: 0.9,
                y: '-70%',
            }
        );
    };

    const mouseOutButton = () => {
        gsap.killTweensOf(buttonBgRef.current);

        gsap.fromTo(
            buttonBgRef.current, 
            {
                y: '-70%',
            },
            {
                ease: Power3.easeOut,
                duration: 0.7,
                y: '-130%',
            }
        );
    };

    useEffect(() => {
        let button = buttonRef.current;
        
        if (window.innerWidth > 850) {
            button!.addEventListener('mouseenter', mouseOverButton);
            button!.addEventListener('mouseleave', mouseOutButton);
        }

        return () => {
            button!.removeEventListener('mouseenter', mouseOverButton);
            button!.removeEventListener('mouseleave', mouseOutButton);
        };
    }, []);

    return (
        <a href={props.link} target='_blank' rel="noreferrer">
            <div className='main-block-tickets-buttons-item' ref={buttonRef}>
                <span>{props.text}</span>
                <div className='main-block-tickets-buttons-item-bg' ref={buttonBgRef}></div>
            </div>
        </a>
    )
};

export default DonateButton;