import './Footer.scss';
import footerLogo from '../../images/footer-logo.svg';
import { Link } from 'react-router-dom';

const detailsData = [
    {
        label: "ФОП",
        text: "ВАСИЛИНКА А.М."
    },

    {
        label: "ЄДРПОУ",
        text: "3322505203"
    },

    {
        label: "IBAN",
        text: "UA483220010000026009310011116"
    },
];

function Footer () {
    return (
        <footer className='footer' id='footer'>
            <div className='footer-container'>
                <div className='footer-details-info'>
                    {
                        detailsData.map((item, index) => (
                            <div key={index} className='footer-detail-item'>
                                <span>{item.label}</span>
                                <span>{item.text}</span>
                            </div>
                        ))
                    }

                    <div className='footer-detail-item-email'>
                        <span className='footer-email-label'>EMAIL</span>
                        <a href='mailto: MOPIS.AGENCY@GMAIL.COM'>
                            <span className='footer-email'>MOPIS.AGENCY@GMAIL.COM</span>
                        </a>
                    </div>
                </div>

                <img className='footer-logo' src={footerLogo} alt={'Footer Logo'}/>

                <div className='footer-add-info'>
                    <span className='footer-rights'>@MOPIS 2023, ВСІ ПРАВА ЗАХИЩЕНІ</span>

                    <div className='footer-policy-info'>
                        <Link to='/privacy-policy'>
                            <span>Політика конфіденційності</span>
                        </Link>

                        <Link to='/public-offer'>
                            <span>Публічний договір</span>
                        </Link>
                    </div>

                    <div className='footer-dev-info'>
                        <span>DESIGN & DEV</span>
                        <a href='https://www.instagram.com/vania_gun/?hl=uk' target='_blank' rel='noreferrer'>
                            <span>IVAN VERGUN</span>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
};

export default Footer;