import './MainBlock.scss';

import donatesData from './donatesData';
import pointIcon from '../../images/point-icon.svg';
import DonateButton from './DonateButton';

const benefitsData = [
    'доступ до онлайн-лекції',
    'додаткові матеріали',
    '2-х місячний доступ до запису'
];

const programData = [
    'Знайомство та шлях Mopis',
    'Визначення своєї ЦА: чому всі про це говорять та як це зробити на реальних прикладах',
    'Позиціонування. Розбір помилок',
    'Як вийти на новий рівень проєктів і чи всім воно треба',
    'Маркетинг: розбір стратегії MopisDecor2023',
    'Базові принципи, що допоможуть сформувати власний маркетинговий план на новий рік',
    'Інстаграм: 5 рішень, які допоможуть виділитись',
    'Як працювати з таргетом: особистий досвід',
    'Ключові інсайти Mopis 2023 року',
    'Q&A'
];

function MainBlock () {
    return (
        <main className='main-block'>
            <div className='main-block-container'>
                <div className='main-block-tickets'>
                    <div className='main-block-tickets-head'>
                        <span>квиток на лекцію</span>
                        <span>№3487232103</span>
                    </div>

                    {/* <div className='main-block-tickets-benefits'>
                        {benefitsData.map((benefit, i) => (
                            <div className='main-block-tickets-benefits-item' key={i}>
                                <img src={pointIcon} alt={'Point'}/>
                                <span>{benefit}</span>
                            </div>
                        ))}
                    </div> */}

                    <div className='main-block-tickets-program'>
                        <span className='main-block-tickets-program-title'>програма лекції</span>

                        <div className='main-block-tickets-program-box'>
                            {programData.map((item, i) => (
                                <div key={i} className='main-block-tickets-program-item'>
                                    <span>{(i + 1).toString().padStart(2, '0')}</span>
                                    <span>{item}</span>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='main-block-tickets-buttons'>
                        <span className='main-block-tickets-buttons-title'>Придбати запис лекції</span>

                        <div className='main-block-tickets-buttons-box'>
                            {donatesData.map((donate, i) => (
                                <DonateButton
                                    key={i}
                                    link={donate.link}
                                    text={donate.text}
                                />
                            ))}
                        </div>
                    </div>

                    <div className='main-block-tickets-bottom'>
                        <span>100% коштів направляються в</span>
                        <a href='https://www.instagram.com/unbroken.ukraine/?hl=uk' target='_blank' rel='noreferrer'>
                            <span className='main-block-tickets-bottom-link'> @unbroken.ukraine</span>
                        </a>
                    </div>
                </div>

                

                <div className='main-block-description'>                 
                    <div className='main-block-description-shedule'>
                        <div className='main-block-description-shedule-info'>
                            <div>запис 14 грудня</div>
                            {/* <div>19:00</div> */}
                        </div>

                        <h1>Маркетинг в Event-сфері</h1>
                        <h2>благодійна онлайн лекція</h2>
                    </div>

                    <p className='main-block-description-text'>Як Івент-спеціалісту сформувати позиціонування та маркетингову стратегію для прориву в 2024. Для організаторів, фотографів, ведучих, декораторів та всіх хто працює у сфері подій та весіль.</p>

                    <div className='main-block-lector'>
                        <div className='main-block-lector-photo'></div>

                        <div className='main-block-lector-text'>
                            <span className='text'>Як Івент-спеціалісту сформувати позиціонування та маркетингову стратегію для прориву в 2024. Для організаторів, фотографів, ведучих, декораторів та всіх хто працює у сфері подій та весіль.</span>
                            <span className='text-mobile'>спікер лекції</span>
                            <h2>Аліса Василинка</h2>
                            <p>Owner Mopis Wedding&Events, MopisSchool, Co-owner Slavna Agency</p>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
};

export default MainBlock;