const donatesData = [
    {
        text: 'Донат $20',
        link: 'https://secure.wayforpay.com/button/b97d173a33bae'
    },

    {
        text: 'Донат $50',
        link: 'https://secure.wayforpay.com/button/ba424992a9834'
    },

    // {
    //     text: 'Донат $100',
    //     link: 'https://secure.wayforpay.com/button/bbc15dd03c2df'
    // },

    {
        text: 'Інша сума від $20',
        link: 'https://secure.wayforpay.com/button/bc99d7f44e615'
    }
];

export default donatesData;